import React from "react"
// import { Link } from "gatsby"
import { useStaticQuery, graphql} from "gatsby"

import Layout from "./layout"
// import CallBox from "./callbox"
import SEO from "./seo"
import Img from "gatsby-image"



const DoctorPage = (props) => {
  const data = useStaticQuery(graphql`
    query {
      allContentfulDoctor {
        edges {
          node {
            id
            name
            bio {
              bio
              id
            }
            picture {
              id
              localFile {
                childImageSharp {
                  fluid(maxWidth: 720) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
      }
    }
  `)
  // const doctors = data.allContentfulDoctor.edges
  const currentDoctor = data.allContentfulDoctor.edges.filter(({node: d}) => d.id === props.pageContext.doctor.id)[0]
  return (
    <Layout >
      <SEO title={currentDoctor.node.name}/>
      <div className="mx-auto container mb-4">
        <div className="flex flex-col justify-center lg:mt-16 lg:flex-row">
          <div className="w-64 lg:min-w-xs p-4 mx-auto lg:mx-px">
            <Img className="rounded-full" aria-label={currentDoctor.node.name + ' U & M Family Eye Care'} fluid={currentDoctor.node.picture.localFile.childImageSharp.fluid} />
          </div>
          <div className="text-navy-500 max-w-md lg:ml-16 px-4 mt-1">
            <p className="font-bold  text-xl lg:pb-8" >{currentDoctor.node.name}</p>
            <div className="font-sans">
              {currentDoctor.node.bio.bio}
            </div>
          </div>
        </div>
      </div>

    </Layout>
  )
}

export default DoctorPage